import React, { useState } from "react";
import { FirestoreCollection } from "react-firestore";
import {
  Text,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  SimpleGrid,
  Box,
  List,
  ListItem,
  ListIcon,
  Input,
} from "@chakra-ui/react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import stc from "string-to-color";
import { questions } from "./Game";
import { db } from "./config";

function Teams({ title, teams }) {
  return (
    <>
      <Text fontSize="xl">{title}</Text>
      <SimpleGrid minChildWidth="300px" spacing="10px">
        {teams.map((team) => {
          return (
            <Box
              key={team.id}
              borderWidth="1px"
              borderRadius="sm"
              p="4"
              w="100%"
            >
              <Box pr="0">
                <Text color={stc(team.name)} fontWeight="semibold">
                  Team {team.name}
                </Text>
              </Box>
              <Box pr="0">
                <List fontWeight="normal" fontSize="sm">
                  {(team.answerIds || []).map((id) => {
                    const value = team.answersById[id];
                    const score = team.scoresById[id];

                    const statusState = {
                      icon: score ? CheckCircleIcon : WarningIcon,
                      color: score ? "green.500" : "red.500",
                    };

                    return (
                      <ListItem key={id}>
                        <ListIcon
                          as={statusState.icon}
                          color={statusState.color}
                        ></ListIcon>
                        <Input
                          variant="unstyled"
                          style={{ display: "inline", width: "1.5em" }}
                          fontWeight="bold"
                          color={statusState.color}
                          defaultValue={score}
                          onChange={(e) => {
                            const nextScore = parseInt(e.target.value);
                            if (!isNaN(nextScore)) {
                              db.doc(team.teamPath).set(
                                {
                                  scoresById: {
                                    ...team.scoresById,
                                    [id]: nextScore,
                                  },
                                },
                                {
                                  merge: true,
                                }
                              );
                            }
                          }}
                        />
                        <Text
                          display="inline"
                          fontWeight="normal"
                          color="gray.500"
                        >
                          {questions[id].question}
                        </Text>{" "}
                        <Text
                          display="inline"
                          fontWeight="bold"
                          color="gray.700"
                        >
                          {value}
                        </Text>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Box>
          );
        })}
      </SimpleGrid>
    </>
  );
}

export default function Manage() {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <FirestoreCollection
      path="companies"
      render={({ data: companies = [] }) => {
        return (
          <Tabs isLazy variant="soft-rounded" onChange={setTabIndex}>
            <TabList
              m="10px"
              mb={0}
              pt="10px"
              pb="10px"
              overflowX="auto"
              whiteSpace="nowrap"
            >
              {companies.map((company) => (
                <Tab key={company.id}>{company.name}</Tab>
              ))}
            </TabList>
            <TabPanels>
              {companies.map((company, i) => (
                <TabPanel key={company.id}>
                  {i === tabIndex && (
                    <FirestoreCollection
                      path={`companies/${company.id}/teams`}
                      render={({ id, data: teams = [] }) => {
                        return (
                          <>
                            <Teams
                              title="Parties en cours"
                              teams={teams.filter((it) => !it.finished)}
                            />
                            <Teams
                              title="Parties terminées"
                              teams={teams.filter((it) => it.finished)}
                            />
                          </>
                        );
                      }}
                    />
                  )}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        );
      }}
    />
  );
}
