import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
// import Code from "./Code";
import Manage from "./Manage";
import Login from "./Login";
import LoginMaster from "./LoginMaster";
import Game from "./Game";

export default function Routes() {
  return (
    <Router>
      <Switch>
        {/* <Route path="/code">
          <Code />
        </Route> */}
        <Route path="/manage">
          <Manage />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/login-master">
          <LoginMaster />
        </Route>
        <Route path="/game/:hash/:type">
          <Game />
        </Route>
        <Route>
          <Redirect to="login" />
        </Route>
      </Switch>
    </Router>
  );
}
